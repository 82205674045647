import React from "react";

const Loading = () => (
    <div className="flex flex-col items-center justify-center w-full py-20">
        <img src="/images/icon_dsearch.jpg" className="w-12 h-12 animation-flip-spin animation-3s animation-ease-in-out" alt="" />
        <h4 className="pt-6 text-sm text-gray-600">Loading...</h4>
    </div>
);

export default Loading;
