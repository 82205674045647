import React, { useContext, useEffect, useState } from "react";
import queryString from "query-string";
import clsx from "clsx";

import loadingChecker from "../Utils/loading-checker";

import { SocketContext } from "../Context/SocketContext";

import Loading from "../Components/Loading";
import PreAd from "../Components/PreAd";
import Package from "../Components/Package";
import BraveInfo from "../Components/BraveInfo";

const Search = ({ location }) => {
    const params = queryString.parse(location.search);
    const query = params.q;

    const socket = useContext(SocketContext);

    const [loading, setLoading] = useState(true);
    const [preAds, setPreAds] = useState([]);
    const [packages, setPackages] = useState([]);
    const [braveInfo, setBraveInfo] = useState(false);

    let sessionId;

    const preAdClick = (e, ad) => {
        socket.emit("preAdClick", {
            adCode: ad.code,
            sessionId: sessionId,
        });
    };

    useEffect(() => {
        socket.io.on("connect_error", () => {
            window.location.href = `https://presearch.org/extsearch?term=${query}&provider_id=25038&redirect`;
        });
        socket.emit("signSystem1", {
            query: query,
            sessionId: sessionId,
            type: "dsearch",
        });
        socket.emit("answers", { query: query, sessionId: sessionId });

        const resultsCheck = loadingChecker(setLoading);

        socket.on(`system1Signature${sessionId}`, (data) => {
            system1Config(data);
        });

        socket.on(`preAds${sessionId}`, (preAdsData) => {
            setPreAds(preAdsData);
        });

        socket.on(`NEW_ANSWER${sessionId}`, (data) => {
            setPackages([data]);
        });

        const system1Config = (signature) => {
            window.s1search =
                window.s1search ||
                function () {
                    (window.s1search.q = window.s1search.q || []).push(arguments);
                };

            let sig = signature.replace(/%2F/g, "_");
            sig = sig.replace(/%2B/g, "-");

            const isSystem1TestMode = process.env.REACT_APP_SYSTEM1_IS_TEST === "true";

            window.s1search("config", {
                category: "web",
                domain: "www.dsearch.com",
                partnerId: 41209,
                isTest: isSystem1TestMode,
                onComplete: (data) => {
                    console.log("Completed");
                },
                onError: (msg) => {
                    setBraveInfo(true);
                },
                query: query,
                segment: window.innerWidth >= 768 ? "dsearch002" : "googlemobile01",
                // signature: this.signature
                signature: sig,
                gdprOptIn: true,
            });
        };

        return function cleanup() {
            // remove content and socket listeners when unmounted
            const top = document.querySelector("[data-s1search=mainline-top]");
            const algo = document.querySelector("[data-s1search=mainline-algo]");
            const pagination = document.querySelector("[data-s1search=pagination]");

            top.innerHTML = "";
            algo.innerHTML = "";
            pagination.innerHTML = "";

            clearInterval(resultsCheck);

            socket.off(`system1Signature${sessionId}`);
            socket.off(`preAds${sessionId}`);
            socket.off(`NEW_ANSWER${sessionId}`);

            setPackages([]);
            setPreAds([]);
            setLoading(true);
        };
    }, [query, sessionId, socket]);

    return (
        <div>
            {loading && <Loading />}
            {braveInfo && <BraveInfo />}
            <div className={clsx({ hidden: loading, block: !loading })}>
                <div id="Packages" className="bg-gray-200 shadow-inner">
                    {packages.map((packageData, index) => (
                        <Package key={index} packageData={packageData} />
                    ))}
                </div>

                <div className="max-w-3xl px-2 sm:px-3">
                    {preAds.map((preAdData, index) => (
                        <PreAd key={index} ad={preAdData} preAdClick={preAdClick} />
                    ))}

                    <div data-s1search="mainline-top"></div>
                    <div data-s1search="mainline-algo"></div>
                    <div data-s1search="pagination"></div>
                </div>
            </div>
        </div>
    );
};

export default Search;
