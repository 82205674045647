import React from "react";
import ReactDOM from "react-dom";
import io from "socket.io-client";

import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { SocketContext } from "./Context/SocketContext";

let socket;

if (process.env.NODE_ENV === "production") {
    socket = io.connect(process.env.REACT_APP_SOCKET_URL, {
        secure: true,
        transports: ["websocket", "polling"],
    });
} else {
    socket = io.connect(process.env.REACT_APP_SOCKET_URL);
}

// console.log("Socket: " + process.env.REACT_APP_SOCKET_URL);

ReactDOM.render(
    <React.StrictMode>
        <SocketContext.Provider value={socket}>
            <App />
        </SocketContext.Provider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
