import React from "react";
import { Link } from "react-router-dom";

const Terms = () => (
    <div className="p-4 mx-auto prose text-gray-600">
        <h1>Terms & Conditions</h1>
        <p>Dsearch.com ("Dsearch") offers a search tool that enables you to easily search other existing search engines and be rewarded.</p>
        <p>
            By using Dsearch, you agree to the terms and conditions (the "Terms") outlined below, as well as any future revisions to the Terms, which will be posted in the
            changelog at the bottom of this document, and which we will notify registered Members of via email or site notification.
        </p>
        <h2>Use of Services</h2>
        <p>
            Dsearch, its subsidiaries, and affiliated organizations offer services to you provided that you are of legal age to form a binding contract and are not a person barred
            from receiving services under the laws of Canada or other applicable jurisdiction. In order to access certain services, you may be required to provide current, accurate
            contact, and other information as part of the registration process and/or continued use of Dsearch services. You are responsible for maintaining the confidentiality of
            your account password, and are responsible for all activities that occur under your account. You agree to immediately notify Dsearch of any unauthorized use of your
            password or account or any other breach of security. Dsearch cannot and will not be liable for any loss or damage arising from your failure to provide us with accurate
            information or to keep your password secure.
        </p>
        <h2>Conduct</h2>
        <p>
            You understand that all information, data, text, software, music, sound, photographs, graphics, video, messages or other materials ("Content") are the sole
            responsibility of the person from which such Content originated. Dsearch reserves the right, but shall have no obligation, to pre-screen, flag, filter, refuse, modify
            or move any Content available via Dsearch services. You understand that by using Dsearch services you may be exposed to Content that is offensive, indecent, or
            objectionable, and that you use Dsearch services at your own risk.
        </p>
        <p>
            You agree that you are responsible for your own conduct and any Content that you create, transmit, or display while using Dsearch services and for any consequences
            thereof. You agree to use Dsearch services only for purposes that are legal, proper, and in accordance with the Terms and any applicable policies or guidelines. You
            agree that you will not engage in any activity that interferes with or disrupts Dsearch services or servers or networks connected to Dsearch services.
        </p>
        <h2>Privacy Policy</h2>
        <p>
            Dsearch takes your privacy very seriously. We are implementing the best privacy controls available as the project continues to take shape. In the meantime, please be
            aware that Dsearch will do everything in its power to protect your information, subject to the following limitations.
        </p>
        <p>At this time, the information we collect is as follows:</p>
        <ol>
            <li>Any information you provide through your profile (name, nickname, avatar, etc.)</li>
            <li>Your email address</li>
            <li>Your password</li>
            <li>Your last-used search engine</li>
            <li>Earned token information (when a search was conducted, who sponsored it, provider)</li>
            <li>Time of last search</li>
            <li>Language information</li>
            <li>Your location</li>
            <li>IP address</li>
            <li>Anonymized browser environment information</li>
            <li>Your blockchain wallet address if you request to withdraw your tokens</li>
        </ol>
        <p>
            By using Dsearch services, you acknowledge and agree that Dsearch may access, preserve, and disclose your account information, and any Content associated with that
            account if required to do so by law or in a good faith belief that such access preservation or disclosure is absolutely necessary to: (a) satisfy any applicable law,
            regulation, legal process or enforceable governmental request, (b) enforce the Terms, including investigation of potential violations hereof, (c) detect, prevent, or
            otherwise address fraud, security or technical issues (including, without limitation, the filtering of spam), or (d) protect against imminent harm to the rights,
            property, or safety of Dsearch, its users or the public as required or permitted by law.
        </p>
        <p>
            You understand that the technical processing and transmission of Dsearch services, including your Content, may involve (a) transmissions over various networks; and (b)
            changes to conform and adapt to technical requirements of connecting networks, devices or services. You also understand and agree that your administrator may have
            access to your account and its content, and may suspend or terminate your account access and your ability to modify your account.
        </p>
        <p>
            Additional details of the Dsearch privacy policy, including GDPR compliance, can be found at{" "}
            <Link to="/Privacy" style={{ color: "#3591fc" }}>
                https://www.Dsearch.com/privacy
            </Link>
            .
        </p>
        <h2>Copyright and Trademarks</h2>
        <p>
            Dsearch is building an open source platform that is controlled by the community, for the community. That said, Dsearch services may contain proprietary and confidential
            information that is protected by applicable intellectual property and other laws and treaties. You further acknowledge and agree that Content contained in sponsor
            advertisements or presented to you through Dsearch services may be protected by copyrights, trademarks, service marks, patents, or other proprietary rights and laws.
        </p>
        <h2>Data Storage and Use</h2>
        <p>
            You agree that Dsearch has no responsibility or liability for the deletion or failure to store any Content and other communications maintained or transmitted by Dsearch
            services. You acknowledge that Dsearch may have set no fixed upper limit on the number of transmissions you may send or receive through Dsearch services or the amount
            of storage space used; however, we retain the right, at our sole discretion, to create limits at any time with or without notice. You recognize that third-party
            services that you access through Dsearch may collect information beyond that which Dsearch collects, and that any information you provide, directly or indirectly, to
            those parties is subject to the terms and conditions of those services, and which are outside the control or responsibility of Dsearch.
        </p>
        <p>
            Upon the termination of your use of Dsearch services, including upon receipt of a certificate or other legal document confirming your death, Dsearch will close your
            account, and you will no longer be able to retrieve content contained in that account.
        </p>
        <h2>Modifications to Service</h2>
        <p>
            Dsearch reserves the right at any time and from time to time to modify or discontinue, temporarily or permanently, Dsearch services (or any part thereof) with or
            without notice. You agree that Dsearch shall not be liable to you or to any third party for any modification, suspension, or discontinuance of Dsearch services.
        </p>
        <h2>Sponsorship and Advertising</h2>
        <p>
            Some Dsearch services are supported by sponsorship revenue and may display sponsored promotions on the service. Such sponsorships may be targeted to the content of
            information stored on the Dsearch services, queries made through Dsearch services or other information. The manner, mode and extent of sponsorships by Dsearch on its
            services are subject to change. As consideration for your use of Dsearch services, you agree that Dsearch may place such sponsorships and that Dsearch shall not be
            responsible or liable for any loss or damage of any sort incurred by you as a result of the presence of such sponsors on Dsearch services or your subsequent dealings
            with sponsors. All sponsored content shall be clearly indicated as such.
        </p>
        <h2>External Resources</h2>
        <p>
            Dsearch services may provide, or third parties may provide, links or redirects to other internet sites or resources. Dsearch may have no control over such sites and
            resources and you acknowledge and agree that Dsearch is not responsible for the availability of such external sites or resources, and does not endorse and is not
            responsible or liable for any Content, advertising, products, or other materials on or available from such sites or resources. You further acknowledge and agree that
            Dsearch shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on
            any such Content, goods or services available on or through any such site or resource.
        </p>
        <h2>Indemnity</h2>
        <p>
            You agree to hold harmless and indemnify Dsearch, and its subsidiaries, affiliates, directors, advisors, officers, agents, employees, advertisers, licensors, suppliers,
            or partners, (collectively "Dsearch and Partners") from and against any third party claim arising from or in any way related to your use of Dsearch services, violation
            of the Terms or any other actions connected with use of Dsearch services, including any liability or expense arising from all claims, losses, damages (actual and
            consequential), suits, judgments, litigation costs and attorney fees, of every kind and nature.
        </p>
        <h2>Disclaimer of Warranty</h2>
        <p>You expressly understand and agree that:</p>
        <p>
            Your use of Dsearch services is at your sole risk. Dsearch services are provided on an "As is" and "As available" basis. To the maximum extent permitted by law, Dsearch
            and partners expressly disclaim all warranties and conditions of any kind, whether express or implied, including, but not limited to the implied warranties and
            conditions of merchantability, fitness for a particular purpose and non-infringement.
        </p>
        <p>
            Dsearch and partners do not warrant that (i) Dsearch services will meet your requirements, (ii) Dsearch services will be uninterrupted, timely, secure, or error-free,
            (iii) the results that may be obtained from the use of Dsearch services will be accurate or reliable, (iv) the quality of any products, services, information, or other
            material purchased or obtained by you through Dsearch services will meet your expectations, and (v) any errors in the software will be corrected.
        </p>
        <p>
            Any material downloaded or otherwise obtained through the use of Dsearch services is done at your own discretion and risk and that you will be solely responsible for
            any damage to your computer system or other device or loss of data that results from the download of any such material.
        </p>
        <p>
            No advice or information, whether oral or written, obtained by you from Dsearch or through or from Dsearch services shall create any warranty not expressly stated in
            the terms.
        </p>
        <h2>Limitation of Liability</h2>
        <p>
            You expressly understand and agree that Dsearch and partners shall not be liable to you for any direct, indirect, incidental, special, consequential or exemplary
            damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses (even if Dsearch or partners have been advised of the
            possibility of such damages) resulting from: (i) the use or the inability to use Dsearch services; (ii) the cost of procurement of substitute goods and services
            resulting from any goods, data, information or services purchased or obtained or messages received or transactions entered into through or from Dsearch services; (iii)
            unauthorized access to or alteration of your transmissions or data; (iv) statements or conduct of any third party on Dsearch services; or (v) any other matter relating
            to Dsearch services.
        </p>
        <h2>Exclusions and Limitations</h2>
        <p>
            Nothing in this agreement is intended to exclude or limit any condition, warranty, right or liability which may not be lawfully excluded or limited. Some jurisdictions
            do not allow the exclusion of certain warranties or conditions or the limitation or exclusion of liability for loss or damage caused by negligence, breach of contract
            or breach of implied terms, or incidental or consequential damages. Accordingly, only the limitations in the Limitation of Liability above are lawful in your
            jurisdiction will apply to you and our liability will be limited to the maximum extent permitted by law.
        </p>
        <h2>Notice</h2>
        <p>
            You agree that Dsearch may provide you with notices, including those regarding changes to the Terms, by email, Telegram or postings on Dsearch services. The official
            communication channel for Dsearch is via the support@dsearch.com email address.
        </p>
        <h2>General Information</h2>
        <p>
            Entire Agreement. The Terms (including any policies, guidelines or amendments that may be presented to you from time to time, such as Program Policies and Legal
            Notices) constitute the entire agreement between you and Dsearch and govern your use of Dsearch services, superseding any prior agreements between you and Dsearch for
            the use of Dsearch services. You also may be subject to additional terms and conditions that may apply when you use or purchase certain other Dsearch services,
            affiliate services, third-party content or third-party software.
        </p>
        <p>
            Choice of Law and Forum. The Terms and the relationship between you and Dsearch shall be governed by the laws of the Province of Ontario without regard to its conflict
            of law provisions. You and Dsearch agree to submit to the personal and exclusive jurisdiction of the courts located within Simcoe County, Ontario.
        </p>
        <p>
            Waiver and Severability of Terms. The failure of Dsearch to exercise or enforce any right or provision of the Terms shall not constitute a waiver of such right or
            provision. If any provision of the Terms is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to
            give effect to the parties' intentions as reflected in the provision, and the other provisions of the Terms remain in full force and effect.
        </p>
        <p>
            Statute of Limitations. You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of Dsearch
            services or the Terms must be filed within two (2) years after such claim or cause of action arose or be forever barred.
        </p>
        <h2>Termination</h2>
        <p>
            You may discontinue your use of Dsearch services at any time. You agree that Dsearch may at any time and for any reason, including a period of account inactivity,
            terminate your access to Dsearch services, terminate the Terms, or suspend or terminate your account. In the event of termination, your account will be disabled and you
            may not be granted access to Dsearch services, your account or any files or other content contained in your account. This agreement shall survive expiration or
            termination.
        </p>
        <h2>Contact Information</h2>
        <p>Dsearch.com Inc.</p>
        <p>270 King Street</p>
        <p>Midland, Ontario, Canada</p>
        <p>www.Dsearch.com</p>
        <p>info@Dsearch.com</p>
    </div>
);

export default Terms;
