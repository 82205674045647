import React, { useContext } from "react";
import urldecode from "locutus/php/url/urldecode";

import { UserContext } from "../Context/UserContext";
import clsx from "clsx";

const SideBar = ({ query }) => {
    const [userInfo] = useContext(UserContext);

    return (
        <div
            className={clsx({
                "py-1 bg-gray-100 border-gray-200 border-r border-b": true,
                "sticky top-0": userInfo.stickySidebar,
            })}
        >
            <a href={`${process.env.REACT_APP_PRESEARCH_URL}`} className="block">
                <img className="block p-1 mx-auto w-11 h-11 lg:w-12 lg:h-12" alt="DSearch" src="/images/icon_dsearch.svg" />
            </a>
            <div className="w-full pt-4 space-y-4">
                {userInfo.providers.map((provider, index) => {
                    return (
                        <a
                            key={index}
                            href={provider.url + query}
                            className="relative flex items-center justify-center mx-auto text-center text-gray-500 transition duration-150 cursor-pointer group hover:text-primary-500"
                        >
                            {provider.icon.type === "class" ? (
                                <div className={`text-2xl fa ${provider.icon.class}`}></div>
                            ) : (
                                <div
                                    className="inline-block w-5 h-5 svgIcon"
                                    dangerouslySetInnerHTML={{
                                        __html: urldecode(provider.icon.svg_content),
                                    }}
                                ></div>
                            )}

                            <label className="absolute z-20 px-2 text-xs leading-loose text-white whitespace-no-wrap transition-opacity duration-150 ease-in-out delay-100 transform scale-0 bg-gray-800 border-gray-800 rounded opacity-0 pointer-events-none arrow-left group-hover:scale-100 group-hover:opacity-100 left-14">
                                {provider.name}
                            </label>
                        </a>
                    );
                })}
            </div>
        </div>
    );
};

export default SideBar;
