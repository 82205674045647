import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import queryString from "query-string";
import clsx from "clsx";

import { UserContext } from "../Context/UserContext";

import Header from "../Components/Header";
import SideBar from "../Components/SideBar";
import HelloBar from "../Components/HelloBar";

const Wrapper = (props) => {
    const [userInfo] = useContext(UserContext);
    const { location, children } = props;

    const params = queryString.parse(location.search);
    const query = params.q;

    document.title = `${query} - DSearch`;

    if (!query) {
        return <Redirect to="/" />;
    }

    return (
        <div className="relative flex bg-white">
            {userInfo.announcements && userInfo.announcements.length > 0 && <HelloBar />}
            <div
                className={clsx({
                    "w-11 sm:w-16 flex-none": true,
                    "hidden sm:block": !userInfo.mobileSidebar,
                })}
            >
                <SideBar query={query} />
            </div>
            <div className="flex flex-col flex-auto w-full min-h-screen">
                <Header {...props} query={query} />

                <div className="flex-auto bg-white border-t border-gray-200">{children}</div>
            </div>
        </div>
    );
};

export default Wrapper;
