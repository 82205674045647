import React, { useContext, useEffect, useState, useRef } from "react";
import queryString from "query-string";
import InfiniteScroll from "react-infinite-scroller";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Lightbox from "react-image-lightbox";
// import "react-image-lightbox/style.css";

import forceHttps from "../Utils/force-https";

import { SocketContext } from "../Context/SocketContext";

import Loading from "../Components/Loading";

const ImageCaption = ({ image }) => {
    const classes = {
        label: "font-bold",
        button: "text-xs px-2 py-1 bg-gray-800 text-white rounded",
    };

    const captionInfo = {
        Title: image.name,
        Dimensions: `${image.width} x ${image.height}`,
        Format: image.encodingFormat,
        "File Size": image.contentSize,
    };

    return (
        <div className="text-sm">
            {Object.keys(captionInfo).map((label, index) => (
                <div key={index}>
                    <span className={classes.label}>{label}:</span> {captionInfo[label]}
                </div>
            ))}
            <div className="py-2 space-x-2">
                <a className={classes.button} href={image.contentUrl} target="_blank" rel="noopener noreferrer">
                    Full Image
                </a>
                <a className={classes.button} href={image.hostPageUrl} target="_blank" rel="noopener noreferrer">
                    Visit Site
                </a>
            </div>
        </div>
    );
};

const Images = ({ location, history }) => {
    const params = queryString.parse(location.search);
    const query = params.q;

    const pageSize = 40;

    const socket = useContext(SocketContext);

    const [loading, setLoading] = useState(true);
    const [images, setImages] = useState([]);
    const [hasMore, setHasMore] = useState(false);

    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    const imagesRef = useRef(images);

    let sessionId;

    const loadMore = (page) => {
        const offset = imagesRef.current.length;

        socket.emit("images", {
            query: query,
            sessionId: sessionId,
            offset: offset,
            limit: pageSize,
        });
    };

    const imageClick = (index) => {
        setPhotoIndex(index);
        setIsOpen(true);
    };

    useEffect(() => {
        socket.emit("images", {
            query: query,
            sessionId: sessionId,
            offset: 0,
            limit: pageSize,
        });
        socket.io.on("connect_error", () => {
            window.location.href = `https://presearch.org/extsearch?term=${query}&provider_id=25038&redirect`;
        });

        socket.on(`IMAGE_RESULTS${sessionId}`, (data) => {
            setHasMore(images.length + data.imageResults.length < data.total);

            setLoading(false);

            const newImages = [...imagesRef.current, ...data.imageResults];

            setImages(newImages);
            imagesRef.current = newImages;
        });

        return function cleanup() {
            socket.off(`IMAGE_RESULTS${sessionId}`);

            setImages([]);
            imagesRef.current = [];
            setLoading(true);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    return (
        <div>
            {loading && <Loading />}
            <div className="flex w-full">
                <InfiniteScroll pageStart={0} initialLoad={false} loadMore={loadMore} hasMore={hasMore} loader={<Loading key={0} />} threshold={500} className="flex-1">
                    <div className="flex flex-wrap">
                        {images.map((image, index) => {
                            return (
                                <LazyLoadImage
                                    key={index}
                                    src={forceHttps(image.thumbnailUrl)}
                                    alt={image.name}
                                    id={image.imageId}
                                    className="flex-auto object-cover h-32 m-1 cursor-pointer sm:h-48"
                                    style={{ maxWidth: "calc(50% - 1rem)" }}
                                    onClick={() => imageClick(index)}
                                />
                            );
                        })}
                    </div>
                </InfiniteScroll>
            </div>
            {isOpen && (
                <Lightbox
                    mainSrc={forceHttps(images[photoIndex].contentUrl)}
                    nextSrc={forceHttps(images[(photoIndex + 1) % images.length].contentUrl)}
                    prevSrc={forceHttps(images[(photoIndex + images.length - 1) % images.length].contentUrl)}
                    mainSrcThumbnail={forceHttps(images[photoIndex].thumbnailUrl)}
                    nextSrcThumbnail={forceHttps(images[(photoIndex + 1) % images.length].thumbnailUrl)}
                    prevSrcThumbnail={forceHttps(images[(photoIndex + images.length - 1) % images.length].thumbnailUrl)}
                    imageCaption={<ImageCaption image={images[photoIndex]} />}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
                    onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
                />
            )}
        </div>
    );
};

export default Images;
