import React, { useContext, useEffect, useState, useRef } from "react";
import queryString from "query-string";
import InfiniteScroll from "react-infinite-scroller";

import forceHttps from "../Utils/force-https";

import { SocketContext } from "../Context/SocketContext";

import Loading from "../Components/Loading";

const Videos = ({ location, history }) => {
    const params = queryString.parse(location.search);
    const query = params.q;

    const pageSize = 10;

    const socket = useContext(SocketContext);

    const [loading, setLoading] = useState(true);
    const [videos, setVideos] = useState([]);
    const [hasMore, setHasMore] = useState(false);

    const videosRef = useRef(videos);

    let sessionId;

    const loadMore = (page) => {
        const offset = videosRef.current.length;

        socket.emit("videos", {
            query: query,
            sessionId: sessionId,
            offset: offset,
            limit: pageSize,
        });
    };

    useEffect(() => {
        socket.emit("videos", {
            query: query,
            sessionId: sessionId,
            offset: 0,
            limit: pageSize,
        });
        socket.io.on("connect_error", () => {
            window.location.href = `https://presearch.org/extsearch?term=${query}&provider_id=25038&redirect`;
        });

        socket.on(`VIDEO_RESULTS${sessionId}`, (data) => {
            setHasMore(videos.length + data.videoResults.length < data.total);

            setLoading(false);

            const newVideos = [...videosRef.current, ...data.videoResults];

            setVideos(newVideos);
            videosRef.current = newVideos;
        });

        return function cleanup() {
            socket.off(`VIDEO_RESULTS${sessionId}`);

            setVideos([]);
            videosRef.current = [];
            setLoading(true);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    return (
        <div>
            {loading && <Loading />}
            <div className="flex w-full">
                <InfiniteScroll pageStart={0} initialLoad={false} loadMore={loadMore} hasMore={hasMore} loader={<Loading key={0} />} threshold={500} className="flex-1">
                    <div className="max-w-3xl px-2 mt-4 space-y-4 sm:px-3">
                        {videos.map((video, index) => {
                            return (
                                <a key={index} href={video.contentUrl} className="flex flex-col p-4 space-y-2 overflow-hidden border rounded shadow sm:space-y-0 sm:flex-row">
                                    <div className="relative flex-shrink-0 w-full h-48 overflow-hidden rounded sm:h-24 sm:w-24">
                                        <img src={forceHttps(video.thumbnailUrl)} alt={video.name} className="absolute top-0 left-0 object-cover w-full h-full" />
                                        <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full">
                                            <img src="/images/playButton.png" alt={video.name} className="w-10 h-10 opacity-75" />
                                        </div>
                                    </div>
                                    <div className="space-y-2 sm:pl-4">
                                        <h2 className="text-lg font-semibold leading-tight text-blue-600 visited:text-purple-600">{video.name}</h2>
                                        <p className="text-sm text-gray-800">{video.description}</p>
                                        <div className="flex space-x-2 text-xs">
                                            <span className="font-semibold text-green-700">{video.publisher[0].name}</span>
                                            <span className="text-gray-700">{new Date(video.datePublished).toLocaleDateString("en-US")}</span>
                                        </div>
                                    </div>
                                </a>
                            );
                        })}
                    </div>
                </InfiniteScroll>
            </div>
        </div>
    );
};

export default Videos;
