import React from "react";
import { Link } from "react-router-dom";

import UserInfo from "../Components/UserInfo";
import SearchForm from "../Components/SearchForm";

const Home = ({ history, location }) => {
    return (
        <div id="Home" className="relative flex flex-col items-stretch justify-between min-h-screen">
            <div className="absolute top-0 right-0 mt-4 mr-4">
                <UserInfo home={true} />
            </div>
            <div className="flex flex-col items-center space-y-6" style={{ marginTop: "25vh" }}>
                <img
                    alt="DSearch"
                    src="/images/logo_dsearch.png"
                    style={{
                        maxWidth: "70%",
                        width: "350px",
                        marginBottom: "2vh",
                    }}
                />
                <div className="mx-auto" style={{ maxWidth: "90%", width: "640px", minWidth: "50%" }}>
                    <SearchForm home={true} history={history} location={location} />
                </div>
            </div>
            <div className="p-2 text-xs text-center opacity-75 text-primary-300">
                &copy; {new Date().getFullYear()} Dsearch.com Inc. • <Link to="/privacy">Privacy</Link> • <Link to="/terms">Terms</Link>
            </div>
        </div>
    );
};

export default Home;
