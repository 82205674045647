import React, { useState, useEffect, useRef, useContext } from "react";
import clsx from "clsx";
import axios from "axios";
import Cookie from "js-cookie";
import htmlspecialchars_decode from "locutus/php/strings/htmlspecialchars_decode";

import { UserContext } from "../Context/UserContext";

const handleTransition = (elem) => {
    const transition = setInterval(() => {
        const offset = elem.getBoundingClientRect().height + elem.getBoundingClientRect().top;

        document.body.style.marginTop = `${offset}px`;
    }, 10);

    elem.addEventListener(
        "transitionend",
        () => {
            clearInterval(transition);
        },
        { once: true }
    );
};

const HelloBar = () => {
    const [userInfo] = useContext(UserContext);

    const [show, setShow] = useState(false);

    const helloBar = useRef();

    useEffect(() => {
        setTimeout(() => {
            setShow(true);
            handleTransition(helloBar.current);
        }, 250);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDismiss = () => {
        setShow(false);
        handleTransition(helloBar.current);

        const token = Cookie.get("token");

        axios
            .get(`${process.env.REACT_APP_PRESEARCH_URL}/api/user/update-announcements`, {
                withCredentials: true,
                headers: {
                    Authorization: "Bearer " + token,
                },
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const getHtml = (string) => {
        return { __html: htmlspecialchars_decode(string) };
    };

    return (
        <div
            id="hello-bar"
            ref={helloBar}
            className={`${clsx({
                "-translate-y-full": !show,
                "translate-y-0": show,
            })} fixed top-0 left-0 z-20 w-full py-2.5 px-8 text-center text-yellow-900 transition duration-300 ease-linear origin-top transform bg-yellow-100`}
        >
            <div className="text-base">
                <div dangerouslySetInnerHTML={getHtml(userInfo.announcements[0].content)}></div>
            </div>

            <div className="absolute top-0 right-0 flex items-center h-full mr-2">
                <i onClick={handleDismiss} className="text-yellow-600 transition duration-300 ease-in-out cursor-pointer fa fa-times hover:text-yellow-900"></i>
            </div>
        </div>
    );
};

export default HelloBar;
