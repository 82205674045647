import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { FaClipboard, FaEnvelope, FaFacebookSquare, FaTwitter } from "react-icons/fa";

const shareItems = [
    {
        label: "Facebook",
        icon: <FaFacebookSquare size={20} />,
        color: "#4267b2",
    },
    {
        label: "Twitter",
        icon: <FaTwitter size={20} />,
        color: "#1da1f2",
    },
    {
        label: "Email",
        icon: <FaEnvelope size={20} />,
        color: "#666",
    },
];

const shareLinks = ["https://www.facebook.com/sharer/sharer.php?u=", "https://twitter.com/home?status=", "mailto:?&subject=Check out these results on Dsearch&body="];

const getShareLink = (index, query) => {
    const text = `Check out "${query}" on Dsearch and start earning cryptocurrency when you search!`;
    const link = window.location.href;

    if (index === 0) {
        return `${shareLinks[index]}${link}`;
    } else {
        return `${shareLinks[index]}${text}%0A%0A${link}`;
    }
};

const ShareModal = ({ setShowShare, query }) => {
    const rid = null;

    return (
        <div className="w-11/12 max-w-sm px-3 pt-4 pb-6 bg-white border rounded shadow-xl">
            <div className="text-base font-extrabold text-center">
                Share these results
                <br />
                with the world!
            </div>
            <div className="flex items-center justify-center mt-4 space-x-8">
                {shareItems.map((item, index) => (
                    <a
                        key={index}
                        href={getShareLink(index, query)}
                        onClick={() => setShowShare(false)}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex flex-col items-center space-y-2 cursor-pointer"
                    >
                        <div className="flex items-center justify-center w-12 h-12 text-white rounded-full" style={{ backgroundColor: item.color }}>
                            {item.icon}
                        </div>
                        <p className="text-xs text-center text-gray-800">{item.label}</p>
                    </a>
                ))}

                <CopyToClipboard text={`${window.location.href}${rid ? `&RID=${this.rid}` : ""}`} onCopy={() => setShowShare(false)}>
                    <div className="flex flex-col items-center space-y-2 cursor-pointer">
                        <div className="flex items-center justify-center w-12 h-12 text-white rounded-full" style={{ backgroundColor: "#999" }}>
                            <FaClipboard size={20} />
                        </div>
                        <p className="text-xs text-center text-gray-800">Copy Link</p>
                    </div>
                </CopyToClipboard>
            </div>
        </div>
    );
};

export default ShareModal;
