import queryString from "query-string";

const ABTest = () => {
    const params = queryString.parse(window.location.search);

    const ABParams = [];

    Object.keys(params).map((param) => {
        switch (param) {
            case "ab-include":
                ABParams.push(`${param}=${params[param] || ""}`);
                break;
            case "ab-exclude":
                ABParams.push(`${param}=${params[param] || ""}`);
                break;
            case "ab-user":
                ABParams.push(`${param}=${params[param] || ""}`);
                break;
            default:
        }

        return null;
    });

    return ABParams.join(",");
};

export default ABTest;
