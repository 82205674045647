import React from "react";

const Privacy = () => (
    <div className="p-4 mx-auto prose text-gray-600">
        <h1>Privacy Policy</h1>
        <p>Last Updated: July 14th, 2019</p>
        <p>
            This Privacy Policy describes how Dsearch.com Inc. (Dsearch) collects, uses and discloses information, and what choices you have with respect to the information. This
            Policy applies to the Dsearch.com site and services (collectively, "Services") such as apps and browser extensions.
        </p>
        <p>Updates in this version of the Privacy Policy reflect changes in data protection law.</p>
        <p>When we refer to "Dsearch", we mean the Dsearch entity that acts as the repository of your Dsearch-related information.</p>
        <p>
            By using Dsearch Services, you accept the terms of this Policy and our Terms of Service, and consent to our initial collection, use, disclosure, and retention of your
            information as described in this Policy and Terms of Service.
        </p>
        <p>
            Please note that this Policy does not apply to information collected through third-party websites or services that you may access through the Services or that you
            submit to us through email, text message or other electronic message or offline.
        </p>
        <p>If you are visiting this site from the European Union (EU), see our Notice to EU Data Subjects below for our legal bases for processing and transfer of your data.</p>
        <h2>WHAT WE COLLECT</h2>
        <p>We get information about you in a range of ways.</p>
        <h3>Information You Give Us:</h3>
        <p>Information we may collect from you includes:</p>
        <ul>
            <li>Any information you provide through your profile (name, nickname, avatar, etc.)</li>
            <li>Your email address</li>
            <li>Your password (encrypted)</li>
            <li>Your last-used search engine</li>
            <li>Earned token information (when a search was conducted, who sponsored it, provider)</li>
            <li>Time of last search</li>
            <li>Language information</li>
            <li>Your location</li>
            <li>Your blockchain wallet address if you request to withdraw your tokens</li>
        </ul>
        <p>Information Automatically Collected:</p>
        <p>
            We may automatically record certain information about how you use our Site (we refer to this information as "Log Data"). Log Data may include information such as a
            user's Internet Protocol (IP) address, device and browser type, and operating system. We use this information to administer and provide access to the Services
        </p>
        <p>Information we do not collect:</p>
        <b>We do not store your search terms.</b>
        <b>
            If you repeatedly exhibit search activity that appears to violate the Terms & Conditions, and, despite warnings, require additional monitoring to prevent abuse of
            Dsearch, you will first be separately notified and must opt-in to said monitoring.
        </b>
        <b>We do not ever track or store your GPS location.</b>
        <b>We do not use third-party cookies.</b>
        <b>We do not use trackers.</b>
        <b>We do not mix unmarked paid content with organic content.</b>
        <h2>USE OF PERSONAL INFORMATION</h2>
        <p>To provide our service we will use your personal information in the following ways:</p>
        <ul>
            <li>To enable you to access and use the Services</li>
            <li>To comply with law, where we have not been able to successfully fight a data request</li>
            <li>
                We use your personal information as we believe necessary or appropriate to comply with applicable laws, lawful requests and legal process, such as to respond to
                subpoenas or legitimate requests from government authorities
            </li>
            <li>For compliance, fraud prevention, and safety</li>
            <li>
                We may use your personal information to protect, investigate, and deter against fraudulent, unauthorized, or illegal activity, and will ensure that this right is
                not abused and is highly controlled.
            </li>
        </ul>
        <h2>SHARING OF PERSONAL INFORMATION</h2>
        <p>
            We do not share or sell the personal information that you provide us with other organizations without your express consent, except as described in this Privacy Policy.
            We disclose personal information to third parties under the following circumstances:
        </p>
        <p>
            Affiliates. We may disclose your personal information to our subsidiary organizations and parent organizations for purposes consistent with this Privacy Policy.
            Business Transfers. We may share personal information when we do a business deal, or negotiate a business deal, involving the sale or transfer of all or a part of our
            business or assets. These deals can include any merger, financing, acquisition, or bankruptcy transaction or proceeding. Compliance with Laws and Law Enforcement;
            Protection and Safety. We may share personal information with law enforcement agencies or anti-fraud entities for legal, protection, and safety purposes. We may share
            information to comply with laws that we are not able to argue against. We may share information to respond to lawful requests and legal processes that we are not able
            to argue against. Professional Advisors and Service Providers. We may share information with those who need it to do work for us. These recipients may include third
            party companies and individuals to administer and provide the Service on our behalf (such as customer support, hosting, email delivery and database management
            services), as well as lawyers, bankers, auditors, and insurers. Hopefully they will never want or need your personal information, and we can't think of why they would,
            but in case they do, we're asking you up front. Other. You may permit us to share your personal information with other companies or entities of YOUR choosing. Those
            uses will be subject to the privacy policies of the recipient entity or entities. We may also share aggregated and/or anonymized data with others for their own uses. We
            are not currently doing this, but should we need to, we will amend this policy to list who has access to what.
        </p>
        <h2>INTERNATIONAL TRANSFER</h2>
        <p>
            The Company has offices outside of the EU and has affiliates and service providers in the United States and in other countries. Your personal information may be
            transferred to or from the United States or other locations outside of your state, province, country or other governmental jurisdiction where privacy laws may not be as
            protective as those in your jurisdiction.
        </p>
        <p>EU users should read the important information provided below about transfer of personal information outside of the European Economic Area (EEA).</p>
        <h2>HOW INFORMATION IS SECURED</h2>
        <p>
            We retain information we collect as long as it is necessary and relevant to fulfill the purposes outlined in this privacy policy. In addition, we retain personal
            information to comply with applicable law where required, prevent fraud, resolve disputes, troubleshoot problems, assist with any investigation, enforce our Terms of
            Service, and other actions permitted by law. To determine the appropriate retention period for personal information, we consider the amount, nature, and sensitivity of
            the personal information, the potential risk of harm from unauthorized use or disclosure of your personal information, the purposes for which we process your personal
            information and whether we can achieve those purposes through other means, and the applicable legal requirements.
        </p>
        <p>
            In some circumstances we may anonymize your personal information (so that it can no longer be associated with you) in which case we may use this information
            indefinitely without further notice to you.
        </p>
        <p>
            We employ industry standard security measures designed to protect the security of all information submitted through the Services. However, the security of information
            transmitted through the internet can never be guaranteed. We are not responsible for any interception or interruption of any communications through the internet or for
            changes to or losses of data.
        </p>
        <p>
            Users of the Services are responsible for maintaining the security of any password, user ID or other form of authentication involved in obtaining access to password
            protected or secure areas of any of our digital services.
        </p>
        <h2>INFORMATION CHOICES AND CHANGES</h2>
        <p>Accessing, Updating, Correcting, and Deleting your Information</p>
        <p>
            You may access information that you have voluntarily provided through your account on the Services, and can review, correct, or delete it by sending a request to
            privacy@dsearch.com.
        </p>
        <p>
            You can request to change contact choices, opt-out of our sharing with others, and update your personal information and preferences. You must log into your account to
            make such changes.
        </p>
        <h2>CONTACT INFORMATION.</h2>
        <p>We welcome your comments or questions about this Policy, and you may contact us at: privacy@dsearch.com.</p>
        <h2>CHANGES TO THIS PRIVACY POLICY.</h2>
        <p>
            We may change this privacy policy at any time. We encourage you to periodically review this page for the latest information on our privacy practices. If we make any
            changes, we will change the Last Updated date above and the changelog at the bottom.
        </p>
        <p>
            Any modifications to this Privacy Policy will be effective upon our posting of the new terms and/or upon implementation of the changes to the Site (or as otherwise
            indicated at the time of posting). In all cases, your continued use of the the Site or Services after the posting of any modified Privacy Policy indicates your
            acceptance of the terms of the modified Privacy Policy.
        </p>
        <h2>ELIGIBILITY</h2>
        <p>
            If you are under the age of majority in your jurisdiction of residence, you may use the Services only with the consent of or under the supervision of your parent or
            legal guardian. Consistent with the requirements of the Children's Online Privacy Protection Act (COPPA), if we learn that we have received any information directly
            from a child under age 13 without first receiving his or her parent's verified consent, we will use that information only to respond directly to that child (or his or
            her parent or legal guardian) to inform the child that he or she cannot use the Site and subsequently we will delete that information.
        </p>
        <h2>NOTICE TO CALIFORNIA RESIDENTS</h2>
        <p>
            Under California Civil Code Section 1789.3, California users are entitled to the following consumer rights notice: California residents may reach the Complaint
            Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs by mail at 1625 North Market Blvd., Sacramento, CA 95834, or by
            telephone at (916) 445-1254 or (800) 952-5210.
        </p>
        <h2>NOTICE TO EU DATA SUBJECTS</h2>
        <h3>Personal Information</h3>
        <p>
            With respect to EU data subjects, "personal information," as used in this Privacy Policy, is equivalent to "personal data" as defined in the European Union General Data
            Protection Regulation (GDPR).
        </p>
        <h3>Legal Bases for Processing</h3>
        <p>
            We only use your personal information as permitted by law. We are required to inform you of the legal bases of our processing of your personal information, which are
            described in the list below. If you have questions about the legal bases under which we process your personal information, contact us at legal@dsearch.com.
        </p>
        <h3>Processing Purpose</h3>
        <ul>
            <li>Legal Basis</li>
            <li>For compliance, fraud prevention, and safety</li>
            <li>To provide our service</li>
        </ul>
        <p>
            These processing activities constitute our legitimate interests. We make sure we consider and balance any potential impacts on you (both positive and negative) and your
            rights before we process your personal information for our legitimate interests. We do not use your personal information for activities where our interests are
            overridden by any adverse impact on you (unless we have your consent or are absolutely required by law).
        </p>
        <h3>With your consent</h3>
        <p>
            Where our use of your personal information is based upon your consent, you have the right to withdraw it anytime in the manner indicated in the Service or by contacting
            us at privacy@dsearch.com.
        </p>
        <h3>Use for New Purposes</h3>
        <p>
            We may use your personal information for reasons not described in this Privacy Policy, where we are permitted by law to do so and where the reason is compatible with
            the purpose for which we collected it. If we need to use your personal information for an unrelated purpose, we will notify you and explain the applicable legal basis
            for that use. If we have relied upon your consent for a particular use of your personal information, we will seek your consent for any unrelated purpose.
        </p>
        <h3>Your Rights</h3>
        <p>
            Under the GDPR, you have certain rights regarding your personal information. You may ask us to take the following actions in relation to your personal information that
            we hold:
        </p>
        <ul>
            <li>
                Opt-out. Stop sending you direct marketing communications which you have previously consented to receive. We may continue to send you Service-related and other
                non-marketing communications.
            </li>
            <li>Access. Provide you with information about our processing of your personal information and give you access to your personal information.</li>
            <li>Correct. Update or correct inaccuracies in your personal information.</li>
            <li>Delete. Delete your personal information.</li>
            <li>Transfer. Transfer a machine-readable copy of your personal information to you or a third party of your choice.</li>
            <li>Restrict. Restrict the processing of your personal information.</li>
            <li>Object. Object to our reliance on our legitimate interests as the basis of our processing of your personal information that impacts your rights.</li>
        </ul>
        <p>
            You can submit these requests by email to privacy@dsearch.com. We may request specific information from you to help us confirm your identity and process your request.
            Applicable law may require or permit us to decline your request. If we decline your request, we will tell you why, subject to legal restrictions. If you would like to
            submit a complaint about our use of your personal information or response to your requests regarding your personal information, you may contact us at
            privacy@dsearch.com or submit a complaint to the data protection regulator in your jurisdiction. You can find your data protection regulator{" "}
            <a href="http://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm">here</a>.
        </p>
        <h3>Cross-Border Data Transfer</h3>
        <p>
            Please be aware that your personal data will be transferred to, processed, and stored in the United States. Data protection laws in the U.S. may be different from those
            in your country of residence. You consent to the transfer of your information, including personal information, to the U.S. as set forth in this Privacy Policy by
            visiting our site or using our service.
        </p>
        <p>
            Whenever we transfer your personal information out of the EEA to the U.S. or countries not deemed by the European Commission to provide an adequate level of personal
            information protection, the transfer will be based on a data transfer mechanism recognized by the European Commission as providing adequate protection for personal
            information.
        </p>
        <p>Please contact us if you want further information on the specific mechanism used by us when transferring your personal information out of the EEA.</p>
        <h3>Why do we use Cookies?</h3>
        <p>We may use Cookies for the following purposes:</p>
        <ul>
            <li>To allow registered users to stay logged in to the site after they close their browser window;</li>
            <li>To store users' preferences for site functionality; and</li>
            <li>To track site usage so we can improve our site & better understand how people are using it</li>
            <li>To better understand the interests of our customers and our website visitors.</li>
        </ul>
        <p>
            Some Cookies are necessary for certain uses of the Site, and without such Cookies, we would not be able to provide many services that you need to properly use the Site.
            These Cookies, for example, allow us to operate our Site so you may access it as you have requested and let us recognize that you have created an account and have
            logged into that account to access Site content and features. They also include Cookies that enable us to remember your previous actions within the same browsing
            session and secure our Sites.
        </p>
        <p>
            We also use functional Cookies and Cookies for analysis purposes. Functional Cookies enable certain parts of the site to work properly and your user preferences to
            remain known. Analysis Cookies, among other things, collect information on how visitors use our Site, the content and products that users view most frequently, and the
            effectiveness of our advertising. Cookies are either "session" Cookies which are deleted when you end your browser session, or "persistent," which remain until their
            deletion by you (discussed below) or the party who served the cookie. Full details on all of the Cookies used on the Site are available at our Cookie Disclosure table
            below.
        </p>
        <h3>How to disable Cookies.</h3>
        <p>You can generally activate or later deactivate the use of cookies through a functionality built into your web browser.</p>
        <p>To learn more about how to control cookie settings through your browser:</p>
        <ul>
            <li>Click here to learn more about the "Private Browsing" setting and managing cookie settings in Firefox;</li>
            <li>Click here to learn more about "Incognito" and managing cookie settings in Chrome;</li>
            <li>Click here to learn more about "InPrivate" and managing cookie settings in Internet Explorer; or</li>
            <li>Click here to learn more about "Private Browsing" and managing cookie settings in Safari.</li>
        </ul>
        <p>If you want to learn more about cookies, or how to control, disable or delete them, please visit http://www.aboutcookies.org for detailed guidance.</p>
        <p>
            We may link the information collected by Cookies with other information we collect from you pursuant to this Privacy Policy and use the combined information as set
            forth herein. Similarly, the third parties who serve cookies on our Site may link your name or email address to other information they collect, which may include past
            purchases made offline or online, or your online usage information. If you are located in the European Economic Area, you have certain rights that are described above
            under the header "Notice to EU Data Subjects", including the right to inspect and correct or delete the data that we have about you.
        </p>
        <p>Cookies Dsearch uses:</p>
        <table>
            <tr>
                <th>
                    <b>Name of Cookie/Identifier</b>
                </th>
                <th>
                    <b>What does the cookie generally do (e.g., website function and administration, analytics, marketing)?</b>
                </th>
                <th>
                    <b>What type of cookie is it (persistent or session)?</b>
                </th>
                <th>
                    <b>What is the duration of the cookie on the website (if not cleared by the user)?</b>
                </th>
            </tr>
            <tr>
                <td>remember_web</td>
                <td>Denotes if you've clicked the 'remember me' box</td>
                <td>Persistent</td>
                <td>5 years</td>
            </tr>
            <tr>
                <td>Dsearch_session</td>
                <td>Login credentials</td>
                <td>Session</td>
                <td>Browser session</td>
            </tr>
            <tr>
                <td>XSRF-token</td>
                <td>Security</td>
                <td>Session</td>
                <td>Browser session</td>
            </tr>
            <tr>
                <td>dismissed-start-ext</td>
                <td>Hides browser extension notice</td>
                <td>Persistent</td>
                <td>10 years</td>
            </tr>
        </table>
        <p>
            Third-party resources may install additional cookies - ex. Youtube videos embedded on the site. We are taking steps to eliminate these resources to ensure that members
            are not tracked and that cookies are kept to a minimum, preferably, only the ones listed above.
        </p>
    </div>
);

export default Privacy;
