import React from "react";

const BraveInfo = () => {
    return (
        <div className="absolute top-0 left-0 z-50 h-full w-full flex items-center justify-center">
            <div className="flex w-11/12 text-base text-center md:w-auto flex-col p-4 bg-white border rounded md:text-lg">
                <p className="mb-2">It looks like your browser has blocked search results.</p>
                <p>Please disable your adblocker/Brave shields on this page.</p>
            </div>
        </div>
    );
};

export default BraveInfo;
