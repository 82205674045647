const loadingChecker = (callback) => {
    let counter = 0;
    let checkResults = setInterval(() => {
        const top = document.querySelector("[data-s1search=mainline-top]");
        const algo = document.querySelector("[data-s1search=mainline-algo]");

        if (top && algo && (top.childNodes.length || algo.childNodes.length || counter > 50)) {
            counter = 0;
            callback(false);
            clearInterval(checkResults);
        } else {
            counter++;
        }
    }, 250);

    return checkResults;
};

export default loadingChecker;
