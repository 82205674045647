import React, { useContext } from "react";
import clsx from "clsx";

import ABTest from "../Utils/ab-test";

import { UserContext } from "../Context/UserContext";

const UserInfo = ({ home }) => {
    const [userInfo] = useContext(UserContext);

    const textColor = clsx({
        "transition duration-150 ease-in-out": true,
        "text-gray-700 hover:text-primary-500": !home,
        "text-white hover:text-gray-500": home,
    });

    if (userInfo.loading) {
        return (
            <svg className={`w-4 h-4 ${textColor} animation-spin animation-linear`} viewBox="0 0 512 512">
                <path
                    fill="currentColor"
                    d="M460.116 373.846l-20.823-12.022c-5.541-3.199-7.54-10.159-4.663-15.874 30.137-59.886 28.343-131.652-5.386-189.946-33.641-58.394-94.896-95.833-161.827-99.676C261.028 55.961 256 50.751 256 44.352V20.309c0-6.904 5.808-12.337 12.703-11.982 83.556 4.306 160.163 50.864 202.11 123.677 42.063 72.696 44.079 162.316 6.031 236.832-3.14 6.148-10.75 8.461-16.728 5.01z"
                    className=""
                ></path>
            </svg>
        );
    }

    const handleAuth = (e) => {
        e.preventDefault();

        const ABParams = ABTest();

        window.authWindow = window.open(`${process.env.REACT_APP_PRESEARCH_URL}/external-login?${ABParams}`, "_blank", "width=600,height=600");
    };

    return (
        <div>
            {userInfo.loggedIn ? (
                <div className="flex items-center space-x-4">
                    <a href={`${process.env.REACT_APP_PRESEARCH_URL}/account/tokens/rewards`} className={`${home ? "block" : "hidden sm:block"} ${textColor}`}>
                        {userInfo.balance.rewards.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                        })}{" "}
                        PRE
                    </a>
                    <span className={`${home ? "block" : "hidden sm:block"} w-px h-6 ${home ? "bg-white" : "bg-gray-800"}`}></span>
                    <a href={`${process.env.REACT_APP_PRESEARCH_URL}/account`} className={`${textColor}`}>
                        <svg className="w-4 h-4" viewBox="0 0 512 512">
                            <path
                                fill="currentColor"
                                d="M256 288c79.5 0 144-64.5 144-144S335.5 0 256 0 112 64.5 112 144s64.5 144 144 144zm128 32h-55.1c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16H128C57.3 320 0 377.3 0 448v16c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48v-16c0-70.7-57.3-128-128-128z"
                                className=""
                            ></path>
                        </svg>
                    </a>
                </div>
            ) : (
                <div className={`flex items-center space-x-4 ${textColor}`}>
                    <a onClick={handleAuth} href={`${process.env.REACT_APP_PRESEARCH_URL}/external-login`}>
                        Register or Login
                    </a>
                </div>
            )}
        </div>
    );
};

export default UserInfo;
