import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import urldecode from "locutus/php/url/urldecode";

import { UserContext } from "../Context/UserContext";

import UserInfo from "./UserInfo";
import SearchForm from "./SearchForm";
import ShareModal from "./ShareModal";

const Header = ({ query }) => {
    const [userInfo] = useContext(UserContext);

    const [showShare, setShowShare] = useState(false);

    const navClasses = {
        default: "block cursor-pointer px-4 py-2 text-sm font-medium text-gray-700 border-transparent border-b-3",
        active: "text-primary-500 border-blue-500",
    };

    const showShareModal = () => {
        setShowShare(true);
    };

    const handleClickAway = (e) => {
        if (e.target === e.currentTarget) {
            setShowShare(false);
        }
    };

    return (
        <div>
            {showShare && (
                <div onClick={handleClickAway} className="fixed top-0 left-0 z-20 flex items-center justify-center w-full h-full bg-black bg-opacity-75">
                    <ShareModal query={query} setShowShare={setShowShare} />
                </div>
            )}
            <div className="relative z-10 flex flex-col justify-center min-w-full pr-2 -ml-px bg-gray-100 sm:pr-0">
                <div className="flex items-center h-12 max-w-2xl pr-2 ml-1 sm:ml-0">
                    {!userInfo.mobileSidebar && (
                        <a href={`${process.env.REACT_APP_PRESEARCH_URL}`} className="block mx-2 sm:hidden">
                            <img className="w-11 h-11" alt="DSearch" src="/images/icon_dsearch.svg" />
                        </a>
                    )}
                    <SearchForm query={query} />
                </div>
                {!userInfo.mobileSidebar && (
                    <div className="h-12 px-4 border-b sm:hidden">
                        <div className="flex items-center justify-start h-full space-x-6 overflow-x-auto">
                            {userInfo.providers.map((provider, index) => {
                                return (
                                    <a
                                        key={index}
                                        href={provider.url + query}
                                        className="relative flex items-center justify-center text-center text-gray-400 transition duration-150 group max-w-6 hover:text-primary-500"
                                    >
                                        {provider.icon.type === "class" ? (
                                            <div className={`text-2xl fa ${provider.icon.class}`}></div>
                                        ) : (
                                            <div
                                                className="inline-block w-5 h-5 svgIcon"
                                                dangerouslySetInnerHTML={{
                                                    __html: urldecode(provider.icon.svg_content),
                                                }}
                                            ></div>
                                        )}
                                    </a>
                                );
                            })}
                        </div>
                    </div>
                )}
                <div className="flex overflow-auto lg:mt-2">
                    <NavLink to={`/search?q=${query}`} className={navClasses.default} activeClassName={navClasses.active}>
                        All
                    </NavLink>
                    <NavLink to={`/images?q=${query}`} className={navClasses.default} activeClassName={navClasses.active}>
                        Images
                    </NavLink>
                    <NavLink to={`/videos?q=${query}`} className={navClasses.default} activeClassName={navClasses.active}>
                        Videos
                    </NavLink>
                    <NavLink to={`/news?q=${query}`} className={navClasses.default} activeClassName={navClasses.active}>
                        News
                    </NavLink>
                    <div onClick={showShareModal} className={navClasses.default}>
                        Share
                    </div>
                    <div className="flex items-center justify-end flex-1 ml-2 text-xs text-center whitespace-no-wrap border-transparent sm:text-sm border-b-3 sm:pr-4 lg:hidden">
                        <UserInfo />
                    </div>
                </div>

                <div className="absolute top-0 right-0 hidden mt-4 mr-4 lg:block">
                    <UserInfo />
                </div>
            </div>
        </div>
    );
};

export default Header;
