import React from "react";
import InnerHTML from "dangerously-set-html-content";

const Package = ({ packageData }) => {
    const html = packageData.data;
    const info = packageData.packageInfo;

    return (
        <div className="p-4 text-sm leading-none text-gray-700 max-w-7xl">
            <InnerHTML html={html} />
            <div className="mt-2 space-x-1 text-xs text-gray-500">
                {info.author && (
                    <span>
                        Created by <strong>{info.author}</strong>
                    </span>
                )}
                {info.version && <span>Version {info.version}</span>}
                <a href="https://github.com/PresearchOfficial/presearch-packages/issues/new/choose" className="">
                    Feedback
                </a>
            </div>
        </div>
    );
};

export default Package;
