import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import axios from "axios";
import queryString from "query-string";
import Cookie from "js-cookie";

import defaultProviders from "./Utils/default-providers";

import Wrapper from "./Views/Wrapper";

import Home from "./Views/Home";
import Search from "./Views/Search";
import Images from "./Views/Images";
import News from "./Views/News";
import Videos from "./Views/Videos";
import Terms from "./Views/Terms";
import Privacy from "./Views/Privacy";

import { UserContext } from "./Context/UserContext";

import ABTest from "./Utils/ab-test";

const App = () => {
    const params = queryString.parse(window.location.search);

    const token = Cookie.get("token");

    const savedProviders = JSON.parse(window.localStorage.getItem("providers"));

    const initialState = {
        loading: true,
        loggedIn: false,
        providers: savedProviders || defaultProviders,
        balance: null,
        announcements: [],
        stickySidebar: params.sticky === "true" || false,
        mobileSidebar: params.mobile === "true" || false,
    };

    const [userInfo, setUserInfo] = useState(initialState);

    const ABParams = ABTest();

    const fetchData = (token) => {
        axios
            .get(`${process.env.REACT_APP_PRESEARCH_URL}/api/dsearch/user-info?${ABParams}`, {
                headers: {
                    Authorization: "Bearer " + token,
                },
            })
            .then((response) => {
                if (!response || !response.data) {
                    response = { data: {} };
                }
                setUserInfo({
                    ...initialState,
                    loading: false,
                    loggedIn: response.data.success,
                    providers: response.data.providers || initialState.providers,
                    balance: response.data.balance,
                    announcements: response.data.announcements,
                });

                if (response.data.providers) {
                    window.localStorage.setItem("providers", JSON.stringify(response.data.providers));
                }
                if (response.data.jwt) {
                    Cookie.set("token", response.data.jwt);
                }
                if (response.data && !response.data.success) {
                    window.localStorage.removeItem("providers");
                    Cookie.remove("token");
                }
            })
            .catch((error) => {
                setUserInfo({
                    ...initialState,
                    loading: false,
                });
            });
    };

    useEffect(() => {
        window.addEventListener("message", receiveMessage, false);

        function receiveMessage(event) {
            if (event.origin !== process.env.REACT_APP_PRESEARCH_URL) return;

            window.authWindow.close();

            if (event.data.userInfo) {
                setUserInfo({
                    ...initialState,
                    loading: false,
                    loggedIn: event.data.userInfo.success,
                    providers: event.data.userInfo.providers || initialState.providers,
                    balance: event.data.userInfo.balance,
                    announcements: event.data.userInfo.announcements || initialState.announcements,
                });
                if (event.data.userInfo.jwt) {
                    Cookie.set("token", event.data.userInfo.jwt);
                }
            }
        }

        fetchData(token);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Router>
            <UserContext.Provider value={[userInfo, setUserInfo]}>
                <Switch>
                    <Route exact path="/" render={(props) => <Home {...props} />} />
                    <Route path="/terms" render={(props) => <Terms {...props} />} />
                    <Route path="/privacy" render={(props) => <Privacy {...props} />} />
                    <Wrapper>
                        <Switch>
                            <Route path="/search" render={(props) => <Search {...props} />} />
                            <Route path="/results" render={(props) => <Search {...props} />} />
                            <Route path="/images" render={(props) => <Images {...props} />} />
                            <Route path="/videos" render={(props) => <Videos {...props} />} />
                            <Route path="/news" render={(props) => <News {...props} />} />
                        </Switch>
                    </Wrapper>
                    <Redirect to="/" />
                </Switch>
            </UserContext.Provider>
        </Router>
    );
};

export default App;
