import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import clsx from "clsx";

const SearchForm = ({ history, location, query, home }) => {
    const [newQuery, setNewQuery] = useState(query);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!newQuery) {
            return false;
        }

        if (location.pathname === "/") {
            history.push(`/search?q=${encodeURIComponent(newQuery)}`);
        } else {
            history.push(`${location.pathname}?q=${encodeURIComponent(newQuery)}`);
        }
    };

    const handleChange = (value) => {
        setNewQuery(value);
    };

    const classes = clsx({
        "w-full pr-8 focus:outline-none": true,
        "text-xl pl-4 py-1 leading-10": home,
        "pl-4 leading-9 lg:leading-10": !home,
    });

    return (
        <form action="#" method="GET" onSubmit={handleSubmit} className="w-full">
            <div
                className={`relative overflow-hidden ${clsx({
                    "shadow-md rounded lg:mt-1": !home,
                    "shadow-lg rounded": home,
                })}`}
            >
                <input
                    autoFocus={home}
                    placeholder="Search"
                    type="text"
                    autoComplete="off"
                    name="q"
                    defaultValue={query}
                    onChange={(e) => handleChange(e.target.value)}
                    className={classes}
                />
                <button type="submit" className="absolute top-0 right-0 flex items-center h-full px-2 text-primary-500">
                    <svg className="w-8 h-8" fill="currentColor" width="1em" viewBox="0 0 40 40">
                        <g>
                            <path d="m15.9 23.4c4.1 0 7.5-3.4 7.5-7.5s-3.4-7.5-7.5-7.5-7.5 3.3-7.5 7.5 3.3 7.5 7.5 7.5z m10 0l8.2 8.2-2.5 2.5-8.2-8.2v-1.4l-0.5-0.4c-1.9 1.6-4.4 2.5-7 2.5-6.1 0-10.9-4.7-10.9-10.7s4.8-10.9 10.9-10.9 10.7 4.8 10.7 10.9c0 2.6-0.9 5.1-2.5 7l0.4 0.5h1.4z"></path>
                        </g>
                    </svg>
                </button>
            </div>
        </form>
    );
};

export default withRouter(SearchForm);
