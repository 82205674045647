import React from "react";

import styles from "./PreAd.module.css";

const PreAd = (props) => {
    const ad = props.ad;

    return (
        <div className="mt-4 leading-tight">
            <div className={styles.container}>
                <div>
                    <a href={`${process.env.REACT_APP_PRESEARCH_AD_URL}/conversion/${ad.jwt}/go`} target="_blank" rel="noopener noreferrer" className={styles.headline}>
                        {ad.headline}
                    </a>
                </div>
                <div>
                    <a href={`${process.env.REACT_APP_PRESEARCH_AD_URL}/conversion/${ad.jwt}/go`} target="_blank" rel="noopener noreferrer" className={styles.link}>
                        <span className={styles.ad}>Ad</span>
                        {ad.link}
                    </a>
                    <a title="Report Ad" className={styles.flag} href={`${process.env.REACT_APP_PRESEARCH_URL}/report/${ad.code}`} target="_blank" rel="noopener noreferrer">
                        <svg viewBox="0 0 16 16" fill="currentColor">
                            <path fillRule="evenodd" d="M3.5 1a.5.5 0 0 1 .5.5v13a.5.5 0 0 1-1 0v-13a.5.5 0 0 1 .5-.5z" />
                            <path
                                fillRule="evenodd"
                                d="M3.762 2.558C4.735 1.909 5.348 1.5 6.5 1.5c.653 0 1.139.325 1.495.562l.032.022c.391.26.646.416.973.416.168 0 .356-.042.587-.126a8.89 8.89 0 0 0 .593-.25c.058-.027.117-.053.18-.08.57-.255 1.278-.544 2.14-.544a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-.5.5c-.638 0-1.18.21-1.734.457l-.159.07c-.22.1-.453.205-.678.287A2.719 2.719 0 0 1 9 9.5c-.653 0-1.139-.325-1.495-.562l-.032-.022c-.391-.26-.646-.416-.973-.416-.833 0-1.218.246-2.223.916A.5.5 0 0 1 3.5 9V3a.5.5 0 0 1 .223-.416l.04-.026z"
                            />
                        </svg>

                        <svg viewBox="0 0 16 16" fill="currentColor">
                            <path fillRule="evenodd" d="M3.5 1a.5.5 0 0 1 .5.5v13a.5.5 0 0 1-1 0v-13a.5.5 0 0 1 .5-.5z" />
                            <path
                                fillRule="evenodd"
                                d="M3.762 2.558C4.735 1.909 5.348 1.5 6.5 1.5c.653 0 1.139.325 1.495.562l.032.022c.391.26.646.416.973.416.168 0 .356-.042.587-.126a8.89 8.89 0 0 0 .593-.25c.058-.027.117-.053.18-.08.57-.255 1.278-.544 2.14-.544a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-.5.5c-.638 0-1.18.21-1.734.457l-.159.07c-.22.1-.453.205-.678.287A2.719 2.719 0 0 1 9 9.5c-.653 0-1.139-.325-1.495-.562l-.032-.022c-.391-.26-.646-.416-.973-.416-.833 0-1.218.246-2.223.916a.5.5 0 1 1-.515-.858C4.735 7.909 5.348 7.5 6.5 7.5c.653 0 1.139.325 1.495.562l.032.022c.391.26.646.416.973.416.168 0 .356-.042.587-.126.187-.068.376-.153.593-.25.058-.027.117-.053.18-.08.456-.204 1-.43 1.64-.512V2.543c-.433.074-.83.234-1.234.414l-.159.07c-.22.1-.453.205-.678.287A2.719 2.719 0 0 1 9 3.5c-.653 0-1.139-.325-1.495-.562l-.032-.022c-.391-.26-.646-.416-.973-.416-.833 0-1.218.246-2.223.916a.5.5 0 0 1-.554-.832l.04-.026z"
                            />
                        </svg>
                    </a>
                </div>
                <div className={styles.description}>{ad.description}</div>
                <div className={styles.visit}>
                    <a href={`${process.env.REACT_APP_PRESEARCH_AD_URL}/conversion/${ad.jwt}/go`} target="_blank" rel="noopener noreferrer" className={styles.visitButton}>
                        <Arrow /> Visit Website
                    </a>
                </div>
            </div>
        </div>
    );
};

const Arrow = () => {
    return (
        <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="far"
            data-icon="arrow-right"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            className={styles.arrow}
        >
            <path
                fill="currentColor"
                d="M218.101 38.101L198.302 57.9c-4.686 4.686-4.686 12.284 0 16.971L353.432 230H12c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h341.432l-155.13 155.13c-4.686 4.686-4.686 12.284 0 16.971l19.799 19.799c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L235.071 38.101c-4.686-4.687-12.284-4.687-16.97 0z"
            ></path>
        </svg>
    );
};

export default PreAd;
